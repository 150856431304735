<template>
    <styled-interface
        hide-toolbar
        no-padding>
        <onboarding-header :library="true">
            <template #actions>
                <action-button
                    icon="lock"
                    position="left"
                    light
                    normal-text
                    @click="logout()">
                    Logout
                </action-button>
            </template>
        </onboarding-header>

        <router-view :key="$route.path" />
    </styled-interface>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';
import OnboardingHeader from '@/components/globals/OnboardingHeader';
import StyledInterface from '@/components/globals/StyledInterface';

export default {
    name: 'Library',
    title: 'Ad Library',
    components: {
        ActionButton,
        OnboardingHeader,
        StyledInterface
    },
    methods: {
        logout() {
            this.$router.push(
                {
                    name: 'login',
                },
                () => {
                    // Logout after navigation occurs to avoid errors
                    this.$store.dispatch('logout');
                }
            );
        },
    }
};
</script>
